.countdown-container {
    background-color:  #1c2237;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

.countdown-timer {
    background-color: #1f49e1;
    width: 600px;
    height: 100px;
    border: 2px solid black;
    color: white;
    font-family: "Oswald";
    font-size: 30px;

    display: flex;
    justify-content: center;
    align-items: center;
}

.countdown-timer span{
    margin-left: 5px;
    margin-right: 5px
}

.two-numbers{
    width: 2ch;
}

@media screen and (max-width: 820px){
    .countdown-timer {
        width: 100%;
        height: 100%;
        border: 2% solid black;
        color: white;
        font-family: "Oswald";
        font-size: 1rem;
    
        display: flex;
        justify-content: center;
        align-items: center;
    }
}